<template>
  <v-container pa-5 fluid>
    <formulario-produto v-model="payload" :isProduto="true" :createProduct="true" />
  </v-container>
</template>

<script>
import formularioProduto from "./componentes/formularioProduto.vue";
export default {
  components: {
    formularioProduto,
  },
  data(){
    return{
      payload: null,
    }
  },
};
</script>

<style lang="scss">
.border {
  border: 2px dashed gray;
}
</style>
